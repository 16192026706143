@import 'shared/styles/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100vh;
  background-color: $color-service-biege-light ;
}

.title {
  margin-top: 136px;

  font-size: 200px;
  line-height: 200px;
  font-weight: 400;
  color: $color-service-grey-2;
}

.description {
  margin-top: 24px;

  font-size: 20px;
  line-height: 30px;
  color: $color-service-grey-1;
}

.button {
  margin-top: 40px;
}