@import 'shared/styles/colors.module.scss';
@import 'shared/styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;

  cursor: pointer;
  transition: $default-transition;

  &:hover:not(:disabled) {
    transition: $default-transition;
    background-color: $color-service-btns-icon;
  }

  &:active:not(:disabled) {
    transition: $default-transition;
    background-color: transparent;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &--collapsed {
    padding-left: 24px;
  }

  &--active {
    background-color: $color-service-btns-icon;
  }
}

.link {
  text-decoration: none;
}

.label {
  margin: 0;
  margin-left: 8px;

  font-size: 16px;
  height: 20px;

  &:first-child {
    margin-left: 0;
  }
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:first-child {
    margin-left: 0;
  }
}
