@import 'shared/styles/colors.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: $color-service-black-main;
}