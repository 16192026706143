@import 'shared/styles/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100vh;
  min-width: fit-content !important;
  background-color: $color-service-biege-light;
}

.sider {
  max-width: 232px;
  width: 232px;
  transition: 0.3s;
  overflow: hidden;

  &--collapsed {
    max-width: 68px;
    width: 68px;
    transition: 0.3s;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 30px;
  padding: 20px 20px 0 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;

  padding: 0 20px 20px 20px;
}
