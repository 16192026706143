@import 'shared/styles/colors.module.scss';

.container {
  display: flex;
  align-items: center;

  width: 100%;
  height: 68px;
  padding: 0 32px;

  border-bottom: 1px solid $color-service-grey-3;
  box-sizing: border-box;
}
