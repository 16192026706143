@import 'shared/styles/colors.module.scss';

.item {
  display: flex;
  align-items: center;

  width: 100%;
  min-height: 44px;
  padding: 8px 8px;
  border-bottom: 1px solid $color-service-grey-3;
}

.label {
  display: block;
  flex-shrink: 0;

  width: 250px;
  color: $color-service-grey-1;
}

.value {
  width: 100%;
}