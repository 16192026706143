// Animation
//---------------------------------

$default-transition: 0.25s ease;
$modal-transition: 0.6s cubic-bezier(0.55, 0, 0.1, 1);

// Viewports
//---------------------------------

$viewport--sm: 320px;
$viewport--md: 768px;
$viewport--lg: 1024px;
$viewport--xl: 1280px;
$viewport--xxl: 1440px;
