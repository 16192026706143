@import 'shared/styles/colors.module.scss';

.container {
  display: flex;
}

@keyframes borderAnimate {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.item-wrapper {
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
}

.item {
  display: flex;

  height: 32px;

  & ~ hr {
    width: 0;
    height: 4px;
    opacity: 0;
    margin: 0;
    border: none;
  }

  a {
    color: $color-service-black-main;
    text-decoration: none;

    &:hover, &:focus {
      color: $color-service-grey-2;
    }
  }

  &--selected {
    & ~ hr {
      opacity: 1;
      background: $color-service-grey-0;
      animation: borderAnimate 0.25s ease-in forwards;
    }

    a {
      color: $color-service-grey-2;
    }
  }
}

.amount {
  width: fit-content;
  height: fit-content;
  margin-left: 8px;
  padding: 3px 4px;
  background: #FFD18C;
  border-radius: 2px;

  font-size: 14px;
  line-height: 16px;
}

.error {
  margin-left: 8px;
}
