.container {
  display: flex;
}

.rightSide {
  width: 100%;
  height: 100vh;
  min-width: 1000px;
}

.content {
  height: calc(100vh - 68px);
  padding: 24px;

  overflow: hidden;
}
