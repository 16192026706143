@import 'shared/styles/mixins';

html,
body,
#root {
  position: relative;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;

  &-track {
    background-color: transparent;
    border-radius: 5px;
  }

  &-thumb {
    background-color: #acb6c4;
    border-radius: 5px;
  }

  &-corner {
    background-color: #ffffff;
  }
}

a {
  @include link();
}
