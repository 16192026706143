$color-service-black-main: #292823;
$color-service-black: #000000;
$color-service-white: #ffffff;
$color-service-yellow: #fcdf22;
$color-service-orange: #ff9900;
$color-service-red: #fc4c5d;
$color-service-red-light: #ffe0e3;
$color-service-green-light: #e3ef9b;
$color-service-grey-0: #4f4f4f;
$color-service-grey-1: #868686;
$color-service-grey-2: #c6c2bd;
$color-service-grey-3: #e0ddd5;
$color-service-grey-4: #eceae2;
$color-service-biege-light: #f9f5ee;
$color-service-btns-icon: rgba(#705101, 0.06);
$color-brand-blue: #67C2FA;

:export {
  color-service-black-main: $color-service-black-main;
  color-service-black: $color-service-black;
  color-service-white: $color-service-white;
  color-service-yellow: $color-service-yellow;
  color-service-orange: $color-service-orange;
  color-service-red: $color-service-red;
  color-service-red-light: $color-service-red-light;
  color-service-green-light: $color-service-green-light;
  color-service-grey-0: $color-service-grey-0;
  color-service-grey-1: $color-service-grey-1;
  color-service-grey-2: $color-service-grey-2;
  color-service-grey-3: $color-service-grey-3;
  color-service-grey-4: $color-service-grey-4;
  color-service-biege-light: $color-service-biege-light;
  color-service-btns-icon: $color-service-btns-icon;
  color-brand-blue: $color-brand-blue;
}
