@import './colors.module.scss';
@import './variables.module.scss';

@mixin cutStrings($stringAmount, $height: auto) {
    height: $height;

    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: $stringAmount;
    -webkit-line-clamp: $stringAmount;
    line-clamp: $stringAmount;
    display: -webkit-box;
    display: box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    box-orient: vertical;
}

@mixin link {
    color: $color-brand-blue;
    transition: color $default-transition;
    outline: none;
    text-decoration: none;

    cursor: pointer;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        color: rgba($color-brand-blue, 0.6);
    }

    &:active:not(:disabled) {
        color: $color-brand-blue;
    }

    &--disabled {
        color: $color-service-grey-3;
        pointer-events: none;
    }
}

@mixin tableBodyHeight($height) {
    > div > div > div > div > div:last-child {
        height: $height !important;
      }
}
